<template>
  <div class="loginmodal" v-if="state !== 'ready'">
    <div class="modal-content agreeart">
      <p id="mainTM" class="modaltxt" v-if="type === 'header'"> <!--유저페이지에서-->
        <span v-if="state === 'ready'">게임 완전 종료 처리중입니다.<br> 잠시만(1분이내) 기다려주세요.</span>
        <span v-if="state === 'complete'">환전을 위한 유효성 검사가 진행중입니다.<br> 잠시만 기다려주세요.</span>
        <span v-if="state === 'fail'">통합머니로 전환 실패. 고객센터로 문의주세요</span>
      </p>
      <p id="exchTM" class="modaltxt" v-if="type === 'cash'"> <!--출금시-->
        <span>통합머니 처리가 완료되지 않았습니다.<br> 통합머니 전환 버튼을 클릭해주세요.</span>
      </p>
      <p id="partnerTM" class="modaltxt" v-if="type === 'partner'"> <!--파트너페이지에서-->
        <span>해당회원의 통합머니 전환이 이루어지지 않았습니다.<br> 전환진행을 부탁드립니다.</span>
      </p>
      <!-- <button v-if="state === 'complete'" class="yes" @click="autoClose">환전 신청하기</button> -->
      <div v-if="state === 'complete'" class="loadingCircle"></div>
    </div>
  </div>
</template>

<script>
import { setMoneyMerge, setMoneyMergeForPartner } from '@/api/member'

export default {
  name: 'TMmodal',
  props: [
    'type',
    'isOpen',
    'userData'
  ],
  data () {
    return {
      state: 'ready'
    }
  },
  watch: {
    async isOpen () {
      if (this.isOpen) {
        if (this.type !== 'header') {
          this.state = 'ing'
        }
        await this.moneyMerge()
      }
    }
  },
  methods: {
    autoClose () {
      if (this.state === 'complete') {
        setTimeout(() => {
          this.$emit('close')
          this.state = 'ready'
        }, 1800)
      }
    },
    async moneyMerge () {
      console.log('moneyMerge')
      const param = {}
      if (this.userData) {
        param.memId = this.userData.memId
      }
      console.log(param)

      if (this.type === 'header') {
        await setMoneyMerge(param).then(async res => {
          console.log(res)
          const result = res.data
          if (result.resultCode === '0') {
            this.state = 'complete'
          } else if (result.resultCode === 'C009') {
            this.$emit('cancel')
            await this.onAlert('warningart', result.resultMessage)
          } else {
            this.$emit('cancel')
            this.state = 'fail'
          }
        })
      } else {
        await setMoneyMergeForPartner(param).then(async res => {
          console.log(param, res)
          const result = res.data
          if (result.resultCode === '0') {
            this.state = 'complete'
          } else if (result.resultCode === 'C009') {
            await this.onAlert('warningart', result.resultMessage)
            this.$emit('close')
          } else {
            this.state = 'fail'
          }
        })
      }
    }
  },
  updated () {
    this.autoClose()
  }
}
</script>

<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>

<style scoped>
  .modal-content {background: #222; color:#fff; padding: 80px; width: auto;}
  .modaltxt{margin-top:0;text-align: left;font-size:18px;line-height: 2;display: flex;flex-direction: column;}
  br {display: none;}
  @media (max-width: 700px) {
    .modal-content {padding:40px 20px;margin: 50% auto; max-width: 90%; box-sizing: border-box;}
    .loginclose {top:20px; right: 20px;}
    .modaltxt {font-size: 14px;white-space: nowrap;}
    br {display: block;}
  }
</style>
